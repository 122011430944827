body {
  .signin-signup-layout {
    height: 100vh;
    overflow: hidden;

    .logo-placeholder {
      width: 180px;
    }

    // Left section start
    .left-box {
      .left-box___inner {
        padding: 80px;
        padding-top: 130px;
        padding-left: 120px;
        margin-right: 30%;
    
        .heading {
          color: $red;
          margin-top: 60px;
          font-weight: $font-weight-bold;
        }

        .title {
          color: $gray-1000;
          margin-top: 10%;
        }

        .features {
          color: $red;
          margin-top: 8%;
          font-weight: $font-weight-medium;
        }
      }

      @media only screen and (max-width: 720px) {
        .left-box___inner {
          margin-right: 0;
          padding-left: 50px;
          padding-top: 40px;
        }
      }
      @media only screen and (max-width: 956px) {
        .left-box___inner {
         padding-bottom: 20px!important;
        }
      }
      .markers-section {
        position: absolute;
        bottom: 0;
        .markers {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .marker {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    // Left section ends

    .right-box {
      .navigation-box {
        padding: 30px;
        padding-right: 120px;
        padding-bottom: 0;
        text-align: right;
        .navigate {
          cursor: pointer;
        }
      }

      .form-box {
        padding: 40px;
        padding-left: 120px;
        padding-right: 120px;
        .heading {
          text-align: center;
          color: $red;
          font-weight: $font-weight-bold;
          margin-bottom: 30px;
        }
      }
      @media only screen and (max-width: 720px) {
        .form-box {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    .signin-signup-layout {
      overflow: auto;
    }
  }

  .user-type {
    display: flex;
    justify-content: center;
  }

  .privacy-statement {
    text-align: center;
    color: $gray-1000;
    font-size: 12px;
  }

  .note-menu ul {
    padding-top: 0;
    padding-bottom: 0;
  }

  .note-menu > div {
    border-radius: 12px;
  }
}
