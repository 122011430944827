body {
  .chips-fld > div {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .chips-fld input {
    padding-left: 12px !important;
  }
  .inpt-fld-select > div > div {
    box-shadow: none;
    height: 0;
    margin-top: -5px;
    margin-bottom: 10px;
    color: #797979;
    padding-left: 25px;
    font-size: 12px;
    border-radius: 30px;
  }

  .inpt-fld-select.inpt-drpbx > div > div {
    margin-top: 3px;
    margin-bottom: 3px;
    // border-radius: 11px;

  }

  .inpt-fld-select > div:hover fieldset {
    border: 2px solid #a7a3a3 !important;
  }

  .inpt-fld-select > div.Mui-focused fieldset {
    border: 2px solid #a7a3a3 !important;
    border-radius: 11px;

  }

  .inpt-fld-select fieldset {
    border: 2px solid #e2e2e2;
    border-radius: 11px;
  }

  .inpt-fld-invoice-select > div > div {
    box-shadow: none;
    height: 0;
    margin-top: -5px;
    margin-bottom: 10px;
    color: #797979;
    padding-left: 25px;
    font-size: 12px;
    border-radius: 30px;
  }
  .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
    padding-left: 6px; 
}
 
  .inpt-fld-invoice-select > div:hover fieldset {
    border: 3px solid #EBEBEB !important;
  }

  .inpt-fld-invoice-select > div.Mui-focused fieldset {
    border: 3px solid #EBEBEB !important;
    border-radius: 11px;
  }
  .inpt-fld-invoice-select fieldset {
    border: 3px solid #EBEBEB;
    border-radius: 11px;
  }

  .inpt-fld-select > div.Mui-disabled fieldset {
    background: #ededed;
    border: none;
    border-radius: 11px;
    border: 3px solid #EBEBEB !important;

  }

  .inpt-fld-select > div.Mui-disabled svg {
    z-index: 1;
  }

  .inpt-fld-select > div.Mui-disabled fieldset {
    border: none !important;
  }

  .inpt-fld input {
    background: #fff;
    border-radius: 0.5rem;
  }

  .inpt-fld > div.Mui-disabled input {
    background: inherit;
  }

  .inpt-fld > div.Mui-disabled fieldset {
    background: #ededed;
    border: none;
  }

  .inpt-fld > div.Mui-disabled svg {
    z-index: 1;
  }

  .inpt-fld > div.Mui-disabled fieldset {
    border: none !important;
  }

  .inpt-fld > div.Mui-disabled input {
    z-index: 1;
    color: #cecece;
    font-weight: 500;
  }

  .text-fld-active input {
    color: black !important;
  }

  .text-fld-already-invoiced input {
    color: #939393 !important;
  }

  .inpt-fld > div:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
    border: 2px solid #a7a3a3 !important;
  }

  .inpt-fld fieldset {
    transition: border 0.5s;
  }

  .inpt-fld-select fieldset {
    transition: border 0.5s;
  }
  .inpt-fld> div.Mui-error fieldset {
    border: 2px solid red !important;
  }
  .inpt-fld > div.Mui-focused:not(.Mui-error) fieldset {
    border: 2px solid #a7a3a3 !important;
  }

  .inpt-fld > div.Mui-disabled fieldset {
    border: none !important;
  }

  .inpt-fld fieldset {
    border: 2px solid #e2e2e2;
    border-radius: 10;
  }

  .inpt-fld input {
    height: 5px;
    color: #797979;
    padding-left: 25px;
    font-size: 12px;
  }

  .inpt-fld-pd input {
    padding-left: 17px !important;
  }

  .inpt-fld textarea {
    box-shadow: none;
    padding-left: 25px !important;
    color: #797979;
    font-size: 12px;   
     border-radius: 11px;

  }

  .fld-active input {
    background-color: #f4f8fb;
    border-radius: 11px !important;
  }
  .fld-active .Mui-focused input {
    background-color: unset;
    border-radius: unset;
  }

  .inpt-fld-select .MuiSelect-icon,
  .input-dropbox .MuiSelect-icon {
    display: none;
  }

  .inpt-name-fld input {
    text-transform: capitalize;
  }

  .inpt-drpbx.fld-active > div {
    background-color: #f4f8fb;
  }
  .fld-active2 > div {
    background-color: #f4f8fb;
  }
}
