body {
  .box-stat {
    .value {
      color: $red;
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
    }
    .value2 {
      color: $green-dark;
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
    }
    .text {
      color: $gray-900;
      font-weight: $font-weight-medium;
    }
    border-radius: 10px;
  }

  .dashboard-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    margin-left: 10px;
    color: $gray-800;
  }

  .personal-info {
    text-align: center;
    text-align: -webkit-center;
    .avatar-block {
      width: 100px;
      height: 100px;
      background: $gray-700;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      .cameraAltIconAvatar {
        font-size: 40px;
        cursor: pointer;
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
    }
    .fullname {
      font-size: 18px;
      font-weight: 600;
      color: $red;
      margin-top: 10px;
    }
    .location {
      font-size: 12px;
      color: $gray-900;
      margin-top: 3px;
    }
    .information {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      .divider {
        background: $black;
        margin-left: 4px;
        margin-right: 4px;
      }
      .data-value {
        font-weight: bold;
        color: $gray-900;
      }
      .data-text {
        font-size: 10px;
        color: $gray-900;
      }
      .ratings {
        margin-left: 10px;
        color: $gray-900;
      }
    }
    .addresses {
      margin-top: 25px;
      .address {
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
        .address-type {
          font-size: 10px;
          color: $gray-900;
          text-align: left;
        }
        .full-address {
          font-weight: $font-weight-bold;
        }
        .change-address-bttn {
          @include border-radius($border-radius-xl);
        }
      }
    }
  }

  .box-history {
    border-radius: 10px;
    .his-text {
      color: $gray-700;
      font-size: 0.7em;
      font-weight: $font-weight-bold;
    }
    .his-value {
      font-weight: $font-weight-bold;
      font-size: 0.8em;
    }
    .landmark {
      font-size: 1em;
    }
  }

  .icn-area {
    display: flex;
    justify-content: center;
    .icn {
      border: 2px dashed #45c3c5;
      margin: 10px;
    }
  }
}
