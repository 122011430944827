// Core

body {
  .app-sidebar-heading {
    padding: $spacer $spacer ($spacer / 1.1);
    font-weight: bold;
    color: $blue;
    text-transform: uppercase;
    font-size: $font-size-sm;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  // Sidebar menu item

  .app-sidebar-item {
    display: block;
    padding: 2px ($spacer) 1px 0;
    margin-bottom: 2px;
  }

  .app-sidebar-icon-selected {
    display: none;
  }

  .app-sidebar-item:hover .app-sidebar-icon-default {
    display: none;
  }

  .app-sidebar-item:hover .app-sidebar-icon-selected {
    display: block;
  }

  .sidebar-dot, .sidebar-dot2 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 15px;
    transition: background-color 0.5s;
  }
  
  .sidebar-dot3 {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-right: 15px;
    transition: background-color 0.5s;
  }
  // .app-sidebar-item:hover .sidebar-dot {
  //   background: $red;
  // }

  .app-sidebar-item:hover .sidebar-label-text {
    color: $red;
  }

  // .app-sidebar-item:hover .sidebar-dot2 {
  //   background: $green-dark;
  // }

  .app-sidebar-item:hover .sidebar-label-text2 {
    color: $green-dark;
  }

  .app-sidebar-button {
    padding-top: $spacer / 2.4;
    padding-bottom: $spacer / 2.4;
    padding-right: ($spacer / 2);
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0 22px 22px 0;
    font-size: 15px;
    width: 100%;
    font-weight: normal;
    transition: $transition-base;
    color: $blue;
    white-space: nowrap;

    &.active,
    &:hover {
      color: $primary;
      background: $gray-300;
    }
  }

  .app-sidebar-button-wrapper {
    padding: 4px 17px;
    justify-content: flex-start;
    text-transform: none;
    width: 100%;
    border-radius: 3px;
    transition: $transition-base;
    font-weight: normal;

    &.depth-0 {
      font-weight: 600;
    }

    font-size: 12px;
    color: $blue;

    &.active,
    &:hover {
      background: none;
      color: $primary;
    }
  }

  .app-sidebar-icon {
    margin-right: 16px;
  }

  .sidebar-label-text, .sidebar-label-text2 {
    color: $gray-700;
    white-space: nowrap;
  }

  .perfect-scrollbar {
    padding-left: 5px;
    padding-right: 30px;
  }

  .sidebar-expand-icon {
    margin-left: auto;
    height: 16px;
    width: 16px;
    opacity: 0.6;
    transition: $transition-base;
  }

  .sidebar-expand-icon-rotate {
    opacity: 0.9;
    transform: rotate(180deg);
  }

  .menu-item-label {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .active-item {
    color: $primary;
    font-weight: 500;
    .app-sidebar-icon {
      color: $primary;
    }
  }
}
