body {
  .google-map-react-wrapper > div > div {
    border-radius: 20px;
  }
  .google-map-react-wrapper .gmnoprint:nth-of-type(2) > div {
    border-radius: 11px !important;
    width: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .google-map-react-wrapper .gmnoprint:nth-of-type(2) > div button img {
    width: 10px !important;
    height: 10px !important;
  }
  .google-map-react-wrapper .gm-style {
    border-radius: 20px;
    overflow: hidden;
  }
  .google-map-marker {
    width: 60px;
    height: 30px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .google-map-marker:not(.google-map-marker-active):hover,
  .google-map-marker-hover {
    background-color: #adadad;
    z-index: 9999999 !important;
    position: relative;
  }
  .google-map-marker:not(.google-map-marker-active):hover > p {
    color: #000000 !important;
  }
  .google-map-marker-active {
    background: #000000 !important;
    z-index: 999999 !important;
    position: relative;
  }
  .google-map-marker-active > p {
    color: #fff !important;
  }
  .google-map-marker-hovered {
    background: #f0f0f0;
  }
  .google-map-marker-hovered > p {
    color: #000000;
  }
}
